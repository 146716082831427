footer {
  &.main-footer {
    color: var(--color-neutrals-450);
    background: var(--color-neutrals-900);
  }

  .footer-bottom {
    text-align: center;
    padding: 15px 0 5px;

    .footer-social-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      line-height: 25px;
      padding-bottom: 5px;
      padding-left: 0;
      padding-right: 35px;
      margin: 0;

      li {
        margin-left: 24px;
      }
      a {
        display: inline-block;
      }
      svg {
        display: block;
        path {
          transition: all 0.2s ease-in-out;
        }
        &:hover {
          path {
            fill: var(--color-primary-500);
          }
        }
      }
    }

    .footer-info {
      font-family: 'Manrope', sans-serif;
      font-optical-sizing: auto;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.8;
      font-style: normal;
      padding-bottom: 10px;
    }

    @media (min-width: 981px) {
      display: flex;
      justify-content: space-between;
      text-align: left;

      .footer-info {
        order: 1;
      }
      .footer-social-icon {
        order: 2;
        justify-content: unset;
        padding-right: 30px;
      }
    }
  }
}
