.commitment{
  margin-bottom: 40px;
  .commitment__top-info{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .commitment__left{
      position: relative;
      padding-right: 100px;
      .heading{
        margin-top: 10px;
        margin-bottom: 10px;
        word-break: break-word;
      }
      .commitment__edit{
        position: absolute;
        right: 35px;
        top: 30px;
      }
    }
    .commitment__right{
      flex: none;
    }
    .commitment__author{
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      b{
        margin-right: 5px;
        flex: none;
      }
      span{
        white-space: nowrap;
        max-width: 275px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
      div.text{
        display: flex;
        margin-right: 40px;
        @media (max-width: 479px) {
          margin-right: 0;
          flex-wrap: wrap;
        }
        ul{
          margin: 0;
          padding: 0;
          list-style: none;
        }
        .arrow-wrap{
          display: block;
          margin: 0 auto 10px;
          height: 14px;
          width: 20px;
          cursor: pointer;
          .arrow-open{
            transform: translateY(50%) rotate(-135deg)
          }
        }
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      .commitment__left{
        margin-bottom: 30px;
      }
    }
  }
  .text {
    word-break: break-word;
  }
}
.commitment__empty{
  margin-top: 50px;
}