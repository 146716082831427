:root {
  --color-primary-100: #c9ebf9;
  --color-primary-300: #5eabdc;
  --color-primary-400: #2ea3f2;
  --color-primary-500: #074b8c;
  --color-primary-active: #900022;
  --color-neutrals-0: #ffffff;
  --color-neutrals-100: #f7f9fa;
  --color-neutrals-200: #f2f6f9;
  --color-neutrals-300: #e5edf4;
  --color-neutrals-400: #d0ddeb;
  --color-neutrals-450: #cecece;
  --color-neutrals-500: #8095b2;
  --color-neutrals-900: #171717;
  --color-neutrals-1000: #000000;
  --color-error-100: #f9cec7;
  --color-error-400: #dd585e;
  --color-error-500: #900022;
  --color-warning-100: #fdf3cb;
  --color-warning-300: #f5cd64;
  --color-warning-500: #e09304;
  --color-success-100: #e1f9d0;
  --color-success-150: #cff6ba;
  --color-success-200: #bdf3a3;
  --color-success-250: #a4e789;
  --color-success-300: #8adb6f;
  --color-success-400: #5db64e;
  --color-success-500: #228719;
  --color-success-600: #36731c;
  --color-success-700: #2c601a;
}
