.sectors{
  position: relative;
  z-index: 2;
  @media (max-width: 479px) {
    margin-right: -25px;
  }
  .sectors__titles{
    margin-bottom: 16px;
    display: flex;
    h3:first-of-type{
      width: 290px;
      @media (max-width: 767px) {
        width: 175px;
        flex: none;
      }
      @media (max-width: 479px) {
        width: 145px;
      }
    }
    @media (max-width: 479px) {
      margin-bottom: 5px;
      align-items: flex-end;
      padding-right: 20px;
    }
  }
  .MuiTreeItem-group{
    margin: 0;
  }
  .sectors__head{
    display: flex;
    .sectors__row{
      flex-wrap: nowrap;
      border-bottom: 1px solid #E5EDF4;
      border-top: 1px solid #E5EDF4;
    }
    .cell{
      padding: 16px 0;
      font-family: 'Fira Sans', sans-serif;
      text-align: center;
      &:first-of-type{
        max-width: 30px;
        min-width: 30px;
        padding: 0;
        flex: none;
        @media (max-width: 767px) {
          max-width: 20px;
          min-width: 20px;
        }
        @media (max-width: 479px) {
          max-width: 15px;
          min-width: 15px;
        }
      }
      &:nth-of-type(2){
        min-width: 250px;
        text-align: left;
      }
      &:nth-of-type(3){
        font-weight: bold;
      }
    }
  }
  .MuiTreeItem-content{
    border-bottom: 1px solid #E5EDF4;
    width: auto;
  }

  .cell{
    max-width: 65px;
    min-width: 65px;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    height: 48px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      max-width: 55px;
      min-width: 55px;
      font-size: 12px;
      .execution-small{
        width: 54px;
        p{
          font-size: 14px;
        }
      }
    }
    &.cell-all-years{
      justify-content: flex-start;
    }
  }

  .sectors__row{
    display: flex;
    align-items: center;
    position: relative;
  }

  .sectors__body{
    display: flex;
    .sectors__left{
      .MuiTreeItem-root{
        .MuiTreeItem-root{
          .MuiTreeItem-content{
            padding-left: 30px;
            .cell-sticky{
              width: 220px;
            }
            @media (max-width: 767px) {
              padding-left: 20px;
              .cell-sticky{
                width: 130px;
              }
            }
            @media (max-width: 479px) {
              padding-left: 15px;
              .cell-sticky{
                width: 110px;
              }
            }
          }
          .MuiTreeItem-root{
            .MuiTreeItem-content{
              padding-left: 60px;
              .cell-sticky{
                width: 190px;
              }
              @media (max-width: 767px) {
                padding-left: 40px;
                .cell-sticky{
                  width: 110px;
                }
              }
              @media (max-width: 479px) {
                padding-left: 30px;
                .cell-sticky{
                  width: 95px;
                }
              }
            }
          }
        }
      }
      .section:not(.section-0){
        position: relative;
        .section__wrap{
          span{
            position: absolute;
            left: -80px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            color: var(--color-primary-500);
            font-size: 16px;
            line-height: 16px;
          }
          &::before,
          &::after{
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: -33px;
            top: 50%;
            transform: translateY(-50%);
            border-style: solid;
          }
          &::before{
            border-width: 7px 7px 7px 0;
            border-color: transparent var(--color-neutrals-400) transparent transparent;
          }
          &::after{
            border-width: 6px 6px 6px 0;
            border-color: transparent var(--color-neutrals-0) transparent transparent;
            left: -32px;
          }
        }
        &::before{
          content: '';
          display: block;
          width: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: -27px;
          background-color: var(--color-neutrals-0);
          border-left: 1px solid var(--color-neutrals-400);
          box-sizing: border-box;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        @media (max-width: 767px) {
          .section__wrap{
            span{
              font-size: 12px;
              line-height: 16px;
              left: -54px;
            }
            &::before{
              left: -22px;
            }
            &::after{
              left: -21px;
            }
          }
          &::before{
            width: 6px;
            left: -16px;
          }
        }
        @media (max-width: 479px) {
          .section__wrap{
            span{
              font-size: 10px;
              line-height: 14px;
              left: -35px;
            }
            &::before{
              left: -8px;
              border-width: 5px 5px 5px 0;
            }
            &::after{
              left: -7px;
              border-width: 4px 4px 4px 0;
            }
          }
          &::before{
            width: 5px;
            left: -4px;
          }
        }
      }
      .section-0{
        .cell-name{
          font-weight: bold;
        }
      }
    }
    .sectors__right{
      overflow: hidden;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      & > .sectors__row{
        border-bottom: 1px solid #E5EDF4;
        border-top: 1px solid #E5EDF4;
      }
      .MuiTreeItem-iconContainer{
        display: none;
      }
      .MuiTreeItem-root {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      .execution-small{
        margin-right: auto;
      }
      .cell:not(.cell-name) {
        &:hover {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: -9999px;
            bottom: -9999px;
            background-color: rgba(0, 0, 0, 0.04);
            z-index: -1;
          }
        }
      }
    }
  }

  .MuiTreeItem-content{
    align-items: initial;
    .MuiTreeItem-iconContainer{
      align-items: center;
      margin: 0;
      width: 22px;
      padding-right: 8px;
      @media (max-width: 767px) {
        width: 15px;
        padding-right: 5px;
      }
      @media (max-width: 479px) {
        width: 11px;
        padding-right: 4px;
      }
    }
    .MuiTreeItem-label{
      padding-left: 0;
    }
  }
  .MuiTreeItem-label:hover,
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
  .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label,
  .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label{
    background: transparent;
  }
  .MuiTreeItem-content:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }

  .track-horizontal {
    right: 0;
    left: 340px;
    bottom: 0;
    z-index: 987;
    opacity: 1!important;
    .thumb-horizontal{
      background-color: var(--color-neutrals-400);
      border-radius: 4px;
    }
  }

  ul{
    padding: 0;
  }

  .cell-sticky{
    height: 48px;
    display: flex;
    align-items: center;
    border-right: 1px solid var(--color-neutrals-400);
    width: 250px;
    padding-right: 5px;
    .cell-name{
      width: 100%;
      flex: none;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: 479px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    @media (max-width: 767px) {
      width: 150px;
    }
    @media (max-width: 479px) {
      width: 125px;
    }
  }
}

.sectors__tooltip{
  p{
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    span{
      text-transform: lowercase;
      margin-left: 5px;
    }
  }
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    li{
      display: flex;
      justify-content: space-between;
    }
  }
}