.commitments{
  display: flex;
  align-items: flex-start;
  .commitments__years{
    width: 192px;
    margin: 0;
    padding: 0;
    list-style: none;
    border-left: 1px solid var(--color-neutrals-400);
    border-top: 1px solid var(--color-neutrals-400);
    position: relative;
    flex: none;
    &::after{
      content: '';
      position: absolute;
      right: -1px;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: var(--color-neutrals-400);
    }
    li{
      padding: 8px 16px;
      transition: .3s;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.active,
      &:hover{
        background-color: var(--color-neutrals-200);
      }
      &.active{
        font-weight: bold;
      }
    }
  }
  .commitments__items{
    flex-grow: 1;
    padding: 16px 0;
    .infinite-scroll-component{
      overflow: visible!important;
    }
    .commitments__info{
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 0 16px;
      h3{
        margin-bottom: 8px;
      }
      ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li{
          width: 30%;
          font-size: 10px;
          line-height: 16px;
          text-transform: uppercase;
          margin-bottom: 4px;
          b{
            margin-left: 5px;
          }
        }
      }
      @media (max-width: 991px) {
        align-items: baseline;
        ul{
          flex-direction: column;
          li{
            width: 100%;
          }
        }
      }
      .execution h4{
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .commitments__years{
      width: 100%;
      margin-bottom: 30px;
    }
    .commitments__items{
      width: 100%;
      .commitments__info{
        flex-direction: column;
      }
    }
  }
}