.button{
  padding: 8px 12px;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  flex: none;
  transition: .3s;
  outline: none;
  &:hover{
    opacity: .85;
  }
  &:disabled{
    cursor: not-allowed;
    opacity: .5;
  }
  &.primary{
    background-color: var(--color-primary-500);
    border-color: var(--color-primary-500);
    color: var(--color-neutrals-0);
  }
  &.primary-inverse{
    background-color: var(--color-neutrals-0);
    border-color: var(--color-neutrals-400);
    color: var(--color-primary-500);
  }
  .addIcon{
    position: relative;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-right: 17px;
    margin-left: 5px;
    &::before,
    &::after {
      content: '';
      width: 14px;
      height: 2px;
      background-color: var(--color-neutrals-0);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after{
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}