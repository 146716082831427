.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 138px;
  font-weight: 500;
  background-color: var(--color-neutrals-0);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  z-index: 99999;
  transition: all 0.4s ease-in-out;

  button {
    border: none;
    outline: none;
    background: transparent;
  }

  .header__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .header__btns-wrapper {
      display: flex;
      align-items: center;
    }

    .header__lang-switcher__wrapper {
      position: relative;
      width: 42px;
      height: 90px;
      margin-right: 24px;

      button,
      .header__lang-current {
        font-family: 'Manrope', sans-serif;
        font-size: 16px;
        font-weight: 600;
        transition: all 0.3s ease;
        cursor: pointer;
      }

      .header__lang-current {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--color-primary-400);
        line-height: 20px;
        padding: 35px 0;
        user-select: none;

        &::after {
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          background: url('../../assets//images/chevron-down-icon.svg') no-repeat center;
          background-size: 72%;
          margin-left: 8px;
          transition: all 0.3s ease;
        }
      }
      ul {
        position: absolute;
        top: 120%;
        left: -22px;
        width: 75px;
        list-style: none;
        border-top: 4px solid var(--color-primary-400);
        background: var(--color-neutrals-0);
        padding: 15px 0;
        margin: 0;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        z-index: 1;
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        li {
          padding: 8px 25px;
          line-height: 1.2;

          button {
            color: var(--color-neutrals-900);
            line-height: 1;
            padding: 0;

            &:hover,
            &.active {
              color: var(--color-primary-400);
            }
          }
        }
      }

      &:hover {
        .header__lang-current::after {
          transform: rotate(180deg);
        }

        ul {
          top: 99%;
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
    }

    .header__hamburger {
      display: flex;

      .hamburger__btn {
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: var(--color-primary-400);
        padding: 0;
        cursor: pointer;

        span {
          display: inline-block;
          background-image: url('../../assets//images/menu-burger.svg');
          background-repeat: no-repeat;
          background-size: contain;
          width: 24px;
          height: 24px;
          vertical-align: -6px;
        }
        &:hover {
          opacity: 0.85;
        }
      }
      a {
        width: 48px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        &:hover {
          opacity: 0.85;
        }
        path {
          fill: var(--color-primary-500);
        }
        &:not(:last-of-type) {
          margin-right: 8px;
        }
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
    .header__menu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 1s ease-in-out;
      opacity: 0;
      pointer-events: none;
      z-index: 5;
      background-color: rgba(#2ea3f2, 0.95);

      .close-menu {
        position: absolute;
        right: 34px;
        top: 38px;
        width: 22px;
        height: 22px;
        padding: 0;
        cursor: pointer;
        z-index: 1;

        &::before,
        &::after {
          content: '';
          width: 20px;
          height: 2px;
          border-radius: 2px;
          background-color: var(--color-neutrals-0);
          position: absolute;
          top: 50%;
          left: 0;
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
      .menu__wrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        ul {
          list-style: none;
          width: 100%;
          text-align: center;
          padding: 0;
          margin: 0;

          li {
            line-height: 1;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            transition-delay: 0s;

            a {
              display: block;
              color: var(--color-neutrals-0);
              font-size: 28px;
              text-decoration: none;
              font-family: 'Manrope', sans-serif;
              font-optical-sizing: auto;
              font-weight: 500;
              font-style: normal;
              transition: all 0.2s ease-in-out;
              cursor: pointer;
              padding: 14px 0;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }

      &.open {
        opacity: 1;
        pointer-events: auto;

        .menu__wrap {
          ul {
            li {
              opacity: 1;
              &:nth-child(2) {
                transition-delay: 0.6s;
              }
              &:nth-child(3) {
                transition-delay: 0.7s;
              }
              &:nth-child(4) {
                transition-delay: 0.8s;
              }
              &:nth-child(5) {
                transition-delay: 0.9s;
              }
              &:nth-child(6) {
                transition-delay: 1s;
              }
              &:nth-child(7) {
                transition-delay: 1.15s;
              }
            }
          }
        }
      }
    }
    .header__logo {
      text-align: center;
      display: flex;
      padding-left: 4px;

      svg {
        max-height: 74px;
        width: 100%;
        display: block;
        transition: all 0.4s ease-in-out;
      }
    }
  }

  &.fixed {
    height: 112px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease-in-out;

    .header__logo {
      svg {
        max-height: 60px;
      }
    }
  }
  .log-out-btn {
    display: flex;
    height: 36px;
    width: 101px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 16px;
    p {
      color: var(--color-primary-500);
    }
    svg {
      margin-right: 15px;
    }
    path {
      fill: var(--color-primary-500);
    }
    &:hover {
      opacity: 0.85;
    }
    @media (max-width: 991px) {
      width: 40px;
      svg {
        margin-right: 0;
      }
      p {
        display: none;
      }
    }
  }
}
