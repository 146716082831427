.login{
  padding: 20px 0 50px;
  .heading{
    text-align: center;
    margin-bottom: 20px;
  }
  .login__form{
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
    .login__field {
      margin-bottom: 30px;
      width: 100%;
    }

    button{
      background-color: var(--color-primary-500);
      border-radius: 0;
      color: var(--color-neutrals-0);
      width: 100px;
      margin: 0 auto;
      transition: .3s;
      &:hover{
        opacity: 0.85;
      }
    }
  }
}