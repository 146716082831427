.navigatorHeader {
  display: flex;
  padding: 40px 0;
  .navigatorHeader__left {
    width: 50%;
    display: flex;
    svg {
      width: 88px;
      height: 88px;
      flex: none;
      margin-right: 16px;
    }
    h1 {
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 8px;
    }
  }
  .navigatorHeader__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .navigatorHeader__search {
      width: 368px;
      box-sizing: border-box;
      display: flex;
      form {
        width: 100%;
        input {
          box-sizing: border-box;
          border: none;
          width: 100%;
          padding: 10px 16px 10px 0;
          &:focus {
            outline: none;
          }
        }
      }
      .search__wrap {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          svg path {
            fill: var(--color-primary-500);
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    justify-content: space-between;
    .navigatorHeader__right {
      width: 45%;
      .navigatorHeader__search {
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .navigatorHeader__left,
    .navigatorHeader__right {
      width: 100%;
    }
  }
  @media (max-width: 479px) {
    padding: 20px 0;
    .navigatorHeader__left {
      flex-direction: column;
      align-items: center;
    }
  }
}
