.legacyActs{
  width: 50%;
  padding: 16px 16px 0;
  &.border{
    border-left: none;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: var(--color-neutrals-400);
    }
  }
  h3{
    margin-bottom: 8px;
  }
  .legacyActs__label{
    background-color: var(--color-neutrals-100);
    padding: 8px 16px;
    margin: 0;
    font-size: 14px;
    color: var(--color-neutrals-500);
  }
  ul{
    max-height: 160px;
    overflow-y: auto;
    padding-bottom: 18px;
    li{
      padding: 16px;
      transition: .3s;
      &:hover{
        background-color: var(--color-neutrals-200);
      }
      a{
        font-size: 16px;
        line-height: 24px;font-weight: bold;
        text-decoration: none;
        color: var(--color-primary-500);
        transition: .3s;
        position: relative;
        padding-right: 25px;
        display: inline-block;
        &:hover{
          opacity: .85;
        }
        svg{
          position: absolute;
          right: 0;
          top: 2px;
        }
      }
      &::before{
        display: none;
      }
      & ~ .legacyActs__empty{
        display: none;
      }
      &:hover{
        .actionButton-wrap{
          opacity: 1;
        }
      }
    }
  }
  .MuiTabs-root{
    margin-bottom: 20px;
    margin-top: -10px;
    min-height: 0;
    .MuiTab-root{
      min-height: 0;
    }
    .MuiTabs-flexContainer{
      border-bottom: 1px solid var(--color-neutrals-400);
    }
    .MuiTab-wrapper{
      text-transform: none;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-primary-500);
      font-family: 'Fira Sans', sans-serif;
      letter-spacing: normal;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .MuiTabs-indicator{
      background-color: var(--color-primary-500);
      height: 1px;
    }
    .Mui-selected{
      .MuiTab-wrapper{
        font-weight: bold;
      }
    }
  }
  .legacyActs__comment{
    padding-bottom: 16px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}