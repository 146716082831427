@import 'modal';
@import 'variables';

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  background-color: var(--color-neutrals-300);
  padding: 0;
  @media (max-width: 479px) {
    background-color: var(--color-neutrals-0);
  }

  .container {
    padding: 0 16px 16px 16px;
    @media (max-width: 767px) {
      padding: 0 10px 10px 10px;
    }
    @media (max-width: 479px) {
      padding: 0;
    }
  }

  .max-container {
    width: 80%;
    max-width: 1080px;
    margin: auto;

    &.header__content {
      @media (max-width: 1200px) {
        width: calc(100% - 48px);
      }
    }
  }

  .App {
    background-color: var(--color-neutrals-0);
    max-width: 1168px;
    padding-top: 138px;
    margin: 0 auto;
    @media (max-width: 1200px) {
      margin: 0 50px;
    }
    @media (max-width: 767px) {
      margin: 0 30px;
    }
    @media (max-width: 479px) {
      margin: 0 25px;
      &.border {
        border: none;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .heading {
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 479px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .border {
    border: 1px solid var(--color-neutrals-400);
    box-sizing: border-box;
  }
  .border-b {
    border-bottom: 1px solid var(--color-neutrals-400);
  }
  .MuiTooltip-arrow {
    color: var(--color-neutrals-1000);
  }

  .MuiTooltip-tooltip {
    background-color: var(--color-neutrals-1000);
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    b {
      margin-left: 5px;
    }
  }

  .MuiList-root {
    overflow: inherit;
    a {
      text-decoration: none;
      color: var(--color-neutrals-1000);
    }
  }

  .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
  }

  .MuiButton-root,
  .MuiInputBase-root,
  .MuiMenuItem-root,
  .MuiTooltip-tooltip,
  .MuiTypography-body1 {
    font-family: 'Fira Sans', sans-serif;
    text-transform: none;
  }

  .implementation_not_started {
    background-color: var(--color-neutrals-300);
    color: var(--color-primary-500);
  }
  .early_implementation {
    background-color: var(--color-success-100);
    color: var(--color-primary-500);
  }
  .advanced_implementation {
    background-color: var(--color-success-200);
    color: var(--color-primary-500);
  }
  .perfect_execution {
    background-color: var(--color-success-300);
    color: var(--color-primary-500);
  }
  .critical_discrepancy {
    background-color: var(--color-error-100);
    color: var(--color-primary-500);
  }

  .execution-0,
  .execution-n_a {
    background-color: var(--color-neutrals-100);
  }
  .execution-1 {
    background-color: var(--color-success-100);
  }
  .execution-2 {
    background-color: var(--color-success-150);
  }
  .execution-3 {
    background-color: var(--color-success-200);
  }
  .execution-4 {
    background-color: var(--color-success-250);
  }
  .execution-5 {
    background-color: var(--color-success-300);
  }

  .stages-list {
    .stages-item {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .stages-item-0::before {
      background-color: var(--color-neutrals-300);
    }
    .stages-item-1::before {
      background-color: var(--color-success-250);
    }
    .stages-item-2::before {
      background-color: var(--color-success-400);
    }
    .stages-item-3::before {
      background-color: var(--color-success-500);
    }
    .stages-item-4::before {
      background-color: var(--color-error-400);
    }
  }

  .heading-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    @media (max-width: 767px) {
      display: block;
    }
  }

  .w-50 {
    width: 50%;
  }
  @media (max-width: 767px) {
    .w-100-mob {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .d-flex {
    display: flex;
  }
  .space-between {
    justify-content: space-between;
  }
  .align-center {
    align-items: center;
  }

  .arrow {
    width: 7px;
    height: 7px;
    border-right: 2px solid var(--color-primary-500);
    border-bottom: 2px solid var(--color-primary-500);
    transform: rotate(45deg);
    transition: 0.3s;
    @media (max-width: 479px) {
      width: 5px;
      height: 5px;
    }
  }
  .Mui-expanded > .MuiTreeItem-content {
    .arrow {
      transform: rotate(-135deg);
      border-color: var(--color-neutrals-1000);
    }
  }
  .MuiButton-textPrimary,
  .MuiTypography-colorPrimary,
  .MuiPickersDay-current {
    color: var(--color-primary-500);
  }
  .MuiPickersDay-daySelected {
    color: var(--color-neutrals-0);
  }
  .MuiPickersDay-daySelected {
    background-color: var(--color-primary-500);
  }
}
