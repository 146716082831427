.execution{
  padding: 16px 16px 12px;
  flex: none;
  h4{
    font-size: 34px;
    margin: 0;
    line-height: 48px;
  }
}
.execution-small{
  padding: 8px 0 5px;
  text-align: center;
  width: 64px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  p{
    font-size: 16px;
    line-height: 23px;
    font-weight: normal;
  }
  span{
    font-size: 10px;
    line-height: 12px;
    color: var(--color-success-500);
    font-weight: normal;
  }
}