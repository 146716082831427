.commitmentItem{
  padding: 14px 16px 8px;
  position: relative;
  transition: .3s;
  border: 4px solid transparent;
  &:not(:last-of-type)::after{
    content: '';
    position: absolute;
    bottom: -4px;
    height: 1px;
    left: 16px;
    right: 16px;
    background-color: var(--color-neutrals-400);
  }
  &:hover{
    border-color: var(--color-neutrals-400);
    .actionButton-wrap{
      opacity: 1;
    }
  }
  p{
    padding-right: 50px;
  }
  .execution{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    line-height: 28px;
    padding: 4px 8px;
  }

  a{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}