.loader,
.loader:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(0, 126, 167, 0.28);
  border-right: 4px solid rgba(0, 126, 167, 0.28);
  border-bottom: 4px solid rgba(0, 126, 167, 0.28);
  border-left: 4px solid var(--color-primary-500);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner 0.8s infinite linear;
  animation: spinner 0.8s infinite linear;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
