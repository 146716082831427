.tasks{
  display: flex;
  align-items: flex-start;
  .tasks__list{
    width: 50%;
    max-height: 565px;
    overflow-y: auto;
    padding-bottom: 18px;
    .tasks__item{
      transition: .3s;
      cursor: pointer;
      .tasks__wrap{
        padding: 0 16px 0 56px;
        position: relative;
        &:hover{
          .actionButton-wrap{
            opacity: 1;
          }
        }
      }
      &:hover,
      &.active{
        background-color: var(--color-neutrals-200);
        &::before{
          background-color: var(--color-primary-500);
          color: var(--color-neutrals-0);
        }
      }
      &:last-of-type{
        border: none;
      }
      .text{
        margin-bottom: 8px;
      }
      .tasks__labels{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 18px;
        span{
          font-size: 10px;
          line-height: 16px;
          padding: 5px 8px 3px;
          text-transform: uppercase;
          &.label__stage{
            margin-right: 8px;
            font-weight: bold;
          }
          &.label__type{
            color: var(--color-neutrals-500);
          }
          &.label__actions{
            margin-left: auto;
            cursor: pointer;
            color: var(--color-primary-500);
            padding: 4px 8px 2px 32px;
            background-color: var(--color-neutrals-0);
            position: relative;
            &::before{
              content: '';
              width: 6px;
              height: 6px;
              position: absolute;
              left: 12px;
              top: 50%;
              border-right: 2px solid var(--color-primary-500);
              border-bottom: 2px solid var(--color-primary-500);
              transform: translateY(-75%) rotate(45deg);
              transition: .3s;
            }
            &.active::before{
              transform: translateY(-25%) rotate(-135deg);
            }
          }
        }
      }
      .tasks__collapse{
        li{
          position: relative;
          padding: 16px 16px 16px 96px;
          font-size: 14px;
          line-height: 20px;
          &::before{
            font-size: 14px;
            line-height: 14px;
            padding: 4px;
            left: 56px;
          }
          &::after{
            content: '';
            height: 1px;
            background-color: var(--color-neutrals-400);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            transition: none;
          }
        }
      }
    }
  }
  ul{
    margin: 0;
    padding: 0;
    counter-reset: item;
    li{
      display: block;
      position: relative;
      padding-top: 16px;
      &::before{
        content: counters(item, ".") " ";
        counter-increment: item;
        padding: 4px 8px;
        background-color: var(--color-neutrals-200);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: var(--color-primary-500);
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        position: absolute;
        left: 16px;
        transition: .3s;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .tasks__list{
      width: 100%;
      margin-bottom: 30px;
    }
  }
}