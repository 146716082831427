.navigatorInfo{
  margin-top: 56px;
  .navigatorInfo__partners{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    a{
      max-width: 250px;
      flex: 1 0 0;
      img{
        max-width: 100%;
        height: auto;
      }
    }
  }
  .navigatorInfo__text{
    margin-bottom: 56px;
    p:not(:last-of-type){
      margin-bottom: 16px;
    }
  }
  @media (max-width: 767px) {
    margin-top: 20px;
  }
}